<template>
    <div class="surface-0 overflow-hidden">
        <div  id="inicio">
            <Galleria :value="$store.state.slider" :responsiveOptions="responsiveOptions" :numVisible="5"
                :circular="true" containerStyle="max-width: 100%" :showItemNavigators="false" 
                :showThumbnails="false" :showItemNavigatorsOnHover="true" :showIndicators="true"
                :autoPlay="true"  :transitionInterval="2000">
                <template #item="slotProps">
                    <!--
                    <div style="position: absolute; top: 20px; left: 20px; width: 75%;" class="titulo-text">
                        <h1 class="font-bold text-gray-800">
                            <span class="font-light block" >
                                <text style="color:#040F54">
                                    Centro integral de fisioterapia y rehabilitación
                                </text>
                            </span>
                            <text style="color:#040F54">Profesionales con calidad humana</text>
                        </h1>
                        <p class="font-normal md:mt-3 text-gray-700" style="position: absolute; top: 20px; right: 20px; width: 75%;">
                            <text style="color:#040F54">Contáctanos (0414) 797 46 24</text>
                        </p>
                    </div> 
                -->             
                    <img :src="url+'upload/'+slotProps.item.url" :alt="slotProps.item.alt" style="width: 100%;"/>
                </template>
            </Galleria>	
        </div>
        <div class="py-4" id="features">
            <div class="grid justify-content-center">
                <div class="col-12 md:col-12 lg:col-5" v-if="$store.state.slider">
                    <div class="font-bold text-gray-800" style=" font-size: calc(0.5rem + 1.0vw); font-weight: 700; height: 5rem; padding: 1rem 1rem 1rem 2rem">
                        <p style="color:#040F54; ">
                            Somos un centro integral de fisioterapia y rehabilitación enfocado en el bienestar, la salud, la prevención de lesiones y enfermedades creado para toda la familia. 
                        </p>
                        <p style="color:#040F54"  class="font-light block" >
                            Contamos con especialistas de altísimo nivel en áreas como neurocirugia, traumatología, fisiatria, medicina interna, nutrición, psicología, masajes de todo tipo, fisioterapia, pilates y mucho más.
                        </p>
                    </div>
                </div>
                <div class="col-12 md:col-12 lg:col-7"  v-if="$store.state.videos">
                    <Carousel :value="$store.state.videos" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions" circular :autoplayInterval="3000">
                        <template #item="slotProps">
                            <iframe :src="slotProps.data.url" frameborder="0" class="videoyoutube"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen/>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="servicios">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Servicios y Equipo</h2>
                <span class="text-600 text-2xl">Trabajamos con los mejores profesionales de la zona</span>
            </div>
            <div class="grid justify-content-between mt-8 md:mt-0">
                <div class="col-6 lg:col-3 p-0 md:p-3" v-for="(item, index) in $store.state.servicios" :key="index">
                    <div class="p-3 flex flex-column border-200 hover:border-cyan-200" style="border:2px solid; border-radius:10px;">
                        <img :src="url+'upload/'+item.url" class="w-12 h-12 mx-auto" alt="">
                    </div>
                </div>
            </div> 
        </div>

        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
           
            <div class="grid justify-content-center">
                
                <div class="col-12 md:col-12 lg:col-6 p-0 lg:pr-5 lg:pb-5 mt-6 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 h-full justify-content-center  align-items-center text-center" style="border-radius:8px;">
                            <h4 class="text-gray-900 mb-2">
                                Mercedes Caraballo
                                <img src="../assets/images/test1.jpg" class="w-1">
                            </h4>
                            <span class="text-gray-600 ">Testimonio</span>
                            <p>
                                La mejor. Me encantó 🔥💞. Gracias por esta idea tan genial
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-6 p-0 lg:pr-5 lg:pb-5 mt-6 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 h-full justify-content-center  align-items-center text-center" style="border-radius:8px;">
                            <h4 class="text-gray-900 mb-2">
                                Janet Martinez
                                <img src="../assets/images/test2.jpg" class="w-1">
                            </h4>
                            <span class="text-gray-600 ">Testimonio</span>
                            <p>
                                El trato del personal es increíbles, y los resultados mejor, gracias
                            </p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        <!--
        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="productos">
            <div class="grid justify-content-center">
                <div class="col-12 md:col-12 lg:col-12 p-0 lg:pr-12 lg:pb-5 mt-6 lg:mt-0">
                    <CarouselProductos />
                </div>
            </div>
        </div>
        
        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="direccion">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Ubicación</h2>
            </div>
            <div class="py-12 px-12">
                <iframe src="https://maps.google.com/maps?t=m&output=embed&iwloc=near&z=16&q=Casa+NF%2C+Calle+Camaiguana+entre+Tajal%C3%AD+y%2C+2A+C.+Calamar%2C+Lecher%C3%ADa+6016%2C+Anzo%C3%A1tegui@10°11'01.4" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
        -->
    </div>
</template>

<script>
//import CarouselProductos from '@/components/CarouselProductos.vue';
//import Video from '@/components/Video.vue';

export default {
	props: {
		navegar: {
			type: String,
			default: null
		}
	},
	components: {
        //CarouselProductos,
        //Video
	},
	data() {
		return {
			d_navegar: this.navegar,
            temporal: null,
            contador: 0,
			imagen: null,
            layout: 'grid',
            sortKey: null,
            sortOrder: null,
            sortField: null,
            sortOptions: [
                {label: 'Price High to Low', value: '!price'},
                {label: 'Price Low to High', value: 'price'},
            ],
            videos: [],
            url: process.env.VUE_APP_API,
		}
	},
	created() {
	},
	watch: {
		navegar(newValue) {
			this.smoothScroll(newValue);
		}
	},
    methods: {
        smoothScroll(id){
            const ini = document.querySelector('#inicio');
			const el = document.querySelector('#'+id);
			var y = el.getBoundingClientRect().top - 70;           
            const med = ini.getBoundingClientRect().top - 70;
            if(this.temporal != 'inicio' && id != 'inicio'){
                y = y - med;
            }
			window.scrollTo({top: y, behavior: 'smooth'});
            this.temporal = id;         
        },
        onSortChange(event){
            const value = event.value.value;
            const sortValue = event.value;

            if (value.indexOf('!') === 0) {
                this.sortOrder = -1;
                this.sortField = value.substring(1, value.length);
                this.sortKey = sortValue;
            }
            else {
                this.sortOrder = 1;
                this.sortField = value;
                this.sortKey = sortValue;
            }
        },
        
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    
    
}
</script>

<style>
#inicio{
   height: 800px;
}

@media screen and (max-width: 480px) {
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }
    .titulo-text {
        h1 {
            font-size: 20px;
        }
        p {
            font-size: 15px;
        }
    }
    .videoyoutube {
        width: 300px;
        height: 300px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }
    .titulo-text {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .videoyoutube {
        width: 200px;
        height: 315px;
    }

}



@media screen and (min-width: 769px) and (max-width: 1024px) {
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }
    .titulo-text {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .videoyoutube {
        width: 200px;
        height: 315px;
    }

}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }
    .titulo-text {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .videoyoutube {
        width: 100%;
        height: 400px;
    }

}

@media screen and (min-width: 1201px) and (max-width: 1899px) {
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }
    .titulo-text {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .videoyoutube {
        width: 100%;
        height: 350px;
    }

}


/* Extra large devices (large desktops, 1900px and up)*/
@media screen and (min-width: 1900px){
    #inicio{    
        height: auto;
        img {
            width: auto;
            height: auto;
        }
    }

    .titulo-text {
        h1 {
            font-size: 50px;
        }
        p {
            font-size: 25px;
        }
    }
    .videoyoutube {
        width: 100%;
        height: 500px;
    }
}

</style>